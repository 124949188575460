import Layout from "../components/layout"
import React from "react"
import { ContactContent, ContactWrapper } from "../styles/contact/style"
import {
  Title,
  Paragraph,
  MailTo,
} from "../styles/policies/style"

const Contact = () => {
  return (
    <Layout>
      <ContactWrapper>
        <ContactContent>
          <Title>Delivery & Shipping Policy.</Title>
          <Paragraph>
            Your Michael Allen & Partners order will be delivered to your door.
          </Paragraph>
          <Paragraph>
            If the item you have ordered is in stock we aim to dispatch your
            order to you within 1 to 3 working days. If for any reason the item
            is out of stock we will of course inform you immediately and we will
            give you an estimated delivery date.
          </Paragraph>
          <Paragraph>
            We will deliver to the address where your credit card is registered.
            For security reasons please make sure that the address provided is
            where you are most likely to be present to receive the goods. This
            will help ensure that the items you have ordered do not go missing.
          </Paragraph>
          <Paragraph>
            You will be charged as per the Royal Mail standard tariff per order
            of Michael Allen & Partners products. Should you require a
            non-mainland UK or Overseas delivery it will be charged at cost. You
            can contact us for an estimate of this cost prior to dispatch at
            <MailTo href="mailto:info@michaelallenpartners.com">
                info@michaelallenpartners.com
              </MailTo>
          </Paragraph>
          <Paragraph>
            For all international orders, customers are responsible for paying
            the Customs and Import Duties. Unfortunately we cannot determine in
            advance what these fees will be.
          </Paragraph>
          <Paragraph>
            Please be aware that you may be required to sign for delivery.
          </Paragraph>
        </ContactContent>
      </ContactWrapper>
    </Layout>
  )
}

export default Contact
