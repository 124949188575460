import styled from "styled-components"

const Title = styled.h1`
  text-align: center;
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s108};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w200};
`

const Title1 = styled.h3`
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s36};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w500};
`

const Title2 = styled.h5`
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s36};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w500};
`

const Title3 = styled.h5`
  margin-top: 10px;
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s36};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w300};
`

const Paragraph = styled.p`
  margin: ${({ theme: { spacing } }) => spacing.s40} 0;
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s26};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w100};
  line-height: 3.5rem;
`

const Paragraph2 = styled.p`
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s26};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w100};
  line-height: 3.5rem;
`

const MailTo = styled.a`
  color: white;
  text-decoration: underline;
  padding-left: 5px;
`

export { Title, Title1, Title2, Title3, Paragraph, MailTo, Paragraph2 }
